import './App.css';

function App() {
  return (
    <div className="container">
        <h1>
        For an energy supplier with customer service you can trust visit <a href="https://share.octopus.energy/maize-hippo-892" target="_blank" style={{color: "#F050F8"}}>Octopus Energy</a>
        </h1>
    </div>
  );
}

export default App;
